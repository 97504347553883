var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "js-dialog-form js-dialog-attmanage",
      attrs: {
        title: "附件管理",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "js-dialog-attmange-header" }, [
        _c(
          "div",
          { staticClass: "item-box" },
          [
            _c(
              "el-input",
              {
                attrs: { placeholder: "请输入……", size: "small" },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              },
              [
                _c("i", {
                  staticClass: "el-input__icon el-icon-search",
                  attrs: { slot: "suffix" },
                  on: { click: _vm.attManageSearch },
                  slot: "suffix",
                }),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "item-box" },
          [
            _c("div", { staticClass: "sort" }, [_vm._v("排序")]),
            _c(
              "el-select",
              {
                attrs: { placeholder: "请选择……", size: "small" },
                on: { change: _vm.attManageSearch },
                model: {
                  value: _vm.selectValue,
                  callback: function ($$v) {
                    _vm.selectValue = $$v
                  },
                  expression: "selectValue",
                },
              },
              _vm._l(_vm.options, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "upload-box" }, [
        _c(
          "ul",
          [
            _c(
              "li",
              [
                _c(
                  "el-upload",
                  {
                    staticClass: "avatar-uploader",
                    attrs: {
                      action: "#",
                      "show-file-list": false,
                      "auto-upload": false,
                      "on-change": _vm.handleHttpRequest,
                    },
                  },
                  [
                    _c("div", { staticClass: "icon" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/13.png"),
                          alt: "",
                        },
                      }),
                      _c("p", [_vm._v("添加附件")]),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._l(_vm.fileList, function (file) {
              return _c(
                "li",
                {
                  key: file.id,
                  staticClass: "item",
                  class: file.isCur ? "active" : "",
                  on: {
                    click: function ($event) {
                      return _vm.handleFileClick(file)
                    },
                  },
                },
                [
                  _vm.isVideo(file.fullUrl)
                    ? _c("video", {
                        staticClass: "video",
                        attrs: {
                          src: file.fullUrl,
                          autoplay: "true",
                          loop: "true",
                        },
                      })
                    : _c("img", { attrs: { src: file.fullUrl, alt: "" } }),
                  _c("div", { staticClass: "text-box" }, [
                    _c("div", { staticClass: "text" }, [
                      _vm._v(_vm._s(file.name)),
                    ]),
                  ]),
                  _c("i", {
                    staticClass: "el-icon-close",
                    on: {
                      click: function ($event) {
                        return _vm.deleteImage(file)
                      },
                    },
                  }),
                ]
              )
            }),
          ],
          2
        ),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleCancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }