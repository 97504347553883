<template>
  <el-dialog
    class="js-dialog-form js-dialog-attmanage"
    title="附件管理"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="600px"
  >
    <div class="js-dialog-attmange-header">
      <div class="item-box">
        <el-input v-model="name" placeholder="请输入……" size="small">
          <i
            slot="suffix"
            class="el-input__icon el-icon-search"
            @click="attManageSearch"
          ></i>
        </el-input>
        <!-- <el-button type="primary" size="small" @click="attManageSearch">搜索</el-button> -->
      </div>
      <div class="item-box">
        <div class="sort">排序</div>
        <el-select
          v-model="selectValue"
          placeholder="请选择……"
          size="small"
          @change="attManageSearch"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="upload-box">
      <ul>
        <li>
          <el-upload
            class="avatar-uploader"
            action="#"
            :show-file-list="false"
            :auto-upload="false"
            :on-change="handleHttpRequest"
          >
            <div class="icon">
              <img src="@/assets/images/13.png" alt="" />
              <p>添加附件</p>
            </div>
          </el-upload>
        </li>
        <li
          class="item"
          v-for="file in fileList"
          :class="file.isCur ? 'active' : ''"
          :key="file.id"
          @click="handleFileClick(file)"
        >
          <video
            v-if="isVideo(file.fullUrl)"
            :src="file.fullUrl"
            autoplay="true"
            loop="true"
            class="video"
          ></video>
          <img v-else :src="file.fullUrl" alt="" />
          <div class="text-box">
            <div class="text">{{ file.name }}</div>
          </div>
          <i class="el-icon-close" @click="deleteImage(file)"></i>
        </li>
      </ul>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" size="small"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "",
  components: {},
  props: ["value", "fileList"],
  data() {
    return {
      tmpFile: "",
      filetype: "",
      name: "",
      selectValue: 1,
      options: [
        {
          label: "按照上传时间",
          value: 1,
        },
        {
          label: "按照文件名称",
          value: 2,
        },
      ],
      currentFileUrl: "",
      action: "",
      params: "",
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
  },
  mounted() {},
  methods: {
    attManageSearch() {
      this.$emit("attManageSearch", {
        name: this.name,
        type: "",
      });
    },
    handleFileClick(file) {
      this.currentFileUrl = file;
      this.fileList.forEach((item) => {
        if (item.id === file.id) {
          item.isCur = true;
        } else {
          item.isCur = false;
        }
      });
      this.$forceUpdate();
    },
    // 获取上传文件地址
    getUploadDir() {
      return new Promise((resolve, reject) => {
        this.$axios({
          method: "get",
          url: "api/csp/cos/v1/url/upload",
          params: this.params,
        })
          .then((response) => {
            let data = response.data;
            if (!data.status) {
              this.action = data.data;
            } else {
              this.$message({
                type: "error",
                message: data.msg,
                duration: 1000,
              });
            }
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },

    getfiletype(file) {
      let fileSuffix = "";
      let result = "";

      try {
        fileSuffix = file.name.split(".")[1];
      } catch (err) {}

      if (!fileSuffix) {
        result = false;
        return result;
      }

      let imglist = ["jpg", "png", "jpeg"];
      let medialist = ["mp4"];
      result = imglist.some(function (item) {
        return item == fileSuffix;
      });
      // 图片
      if (result) {
        this.params = {
          dirType: "pic",
          suffix: fileSuffix,
        };
        this.filetype = 0;
        return true;
      }

      result = medialist.some(function (item) {
        return item == fileSuffix;
      });

      if (result) {
        this.params = {
          dirType: "media",
          suffix: "mp4",
        };
        this.filetype = 1;
        return true;
      }

      this.$message.error(
        "只允许上传jpg、png、jpeg格式图片；mp4格式视频；请检查格式！"
      );
      return false;
    },

    async handleHttpRequest(file) {
      if (!this.getfiletype(file)) {
        return false;
      }
      await this.getUploadDir();
      var xhr = new XMLHttpRequest();
      xhr.open("PUT", this.action.url, true);
      xhr.onload = (e) => {
        // console.log('上传成功', xhr.status, xhr.statusText)
        // console.log(this.action)
        this.submitImage(file.name, file.size, this.action.file, this.filetype);
      };
      xhr.onerror = (e) => {
        // console.log('上传出错', xhr.status, xhr.statusText)
      };
      xhr.send(file.raw);
    },
    // 提交图片
    submitImage(name, size, url, type) {
      this.$axios({
        method: "post",
        url: "api/csp/task/v1/taskAttach/",
        data: {
          data: {
            name,
            size,
            url,
            type,
          },
        },
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          // 上传成功，更新图片列表
          this.attManageSearch();
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    handleCancel() {
      this.dialogVisible = false;
    },
    handleConfirm() {
      if (this.currentFileUrl.url) {
        this.$emit("receiveFileUrl", this.currentFileUrl);
      } else {
        this.$message({
          type: "error",
          message: "请选择图片！",
          duration: 1000,
        });
        return;
      }
      this.handleCancel();
    },
    // 图片删除
    deleteImage(file) {
      this.$axios({
        method: "delete",
        url: `api/csp/task/v1/taskAttach/${file.id}`,
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          // 上传成功，更新图片列表
          this.attManageSearch();
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    isVideo(url) {
      const arr = url.split(".");
      return arr[arr.length - 1] === "mp4";
    },
  },
};
</script>

<style lang="scss" scoped>
.video {
  width: 120px;
  height: 120px;
}
</style>
